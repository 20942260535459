<template>
    <div>
        <b-row align-v="end">
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">{{ gateIO.name }}</dmx-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                <b-button v-if="gateIO.id != 0" variant="danger" v-b-modal.delete-device-modal class="mr-1">
                    {{ $t('common_delete') }}
                </b-button>
                <b-button variant="success" @click="saveModel()">
                    {{ (gateIO.id != 0) ? $t('common_save') : $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <div class="dmx-card mt-3">
            <b-row no-gutters align-v="center">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('common_name') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-input v-model="gateIO.name"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mt-2" align-v="center">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('gate_type') }}:
                </b-col>
                <b-col lg="10">
                    <b-select :options="gateTypes" v-model="gateIO.type"></b-select>
                </b-col>
            </b-row>
            <b-row class="mt-2" align-v="center">
                <b-col lg="2" align="left" class="pr-2">
                    {{ $t('extra_feature') }}:
                </b-col>
                <b-col lg="10">
                    <b-form-checkbox v-model="gateIO.useSchedule" size="lg">Can use scheduling</b-form-checkbox>
                </b-col>
            </b-row>
        </div>

        <edit-signals v-if="modelId > 0" :gateIO="gateIO" :availableOutputSignals="availableOutputSignals"
            :availableInputSignals="availableInputSignals" :icons="icons"></edit-signals>

        <b-modal size="md" id="delete-device-modal" title="Delete GateIO" @ok="deleteModel()">
            <p>
                You are about to delete this gateIO (<b>{{ gateIO.name }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped></style>

<script>
import modelService from '@/services/modelService';
import router from '@/router';
import { signalType, signalDirection } from '@/variables/variables';

import editSignalsComponent from '@/components/signals/edit-signals.vue';

export default {
    name: 'editModel',
    components: {
        'edit-signals': editSignalsComponent
    },
    props: ["modelId"],
    data: () => ({
        // GateIO variables
        name: '',
        gateIO: {},
        availableOutputSignals: {},
        availableInputSignals: {},
        gateTypes: {},
        icons: {},

        // Crud variables
        deleteDialog: false,

        // validation variables
        formIsValid: true,
    }),
    watch: {
        typeselected(newValue) {
            if (newValue === 40) {
                this.gateIO.useSchedule = false;
            } else {
                this.gateIO.useSchedule = true;
            }
        },
    },
    methods: {
        deleteModel() {
            modelService.deleteModel(this.modelId)
                .then(data => {
                    router.go(-1)
                });
        },
        saveModel() {
            if (!this.formIsValid && this.gateIO.name !== '') {
                return;
            }

            if (this.modelId > 0) {
                modelService.updateModel(this.gateIO)
                    .then(data => {
                        router.go(-1)
                    });
            } else {
                this.gateIO.isTemplate = true;
                modelService.createModel(this.gateIO)
                    .then(data => {
                        router.go(-1)
                    });
            }
        },
        fillView(data) {
            if (data.gateIO) {
                this.gateIO = data.gateIO;
                this.availableOutputSignals = data.outputSignals;
                this.availableInputSignals = data.inputSignals;
                this.gateTypes = data.gateTypes;
                this.icons = data.icons;

                this.name = data.gateIO.name;

                if (this.gateIO.outputSignals == null) {
                    this.gateIO.outputSignals = [];
                }
                if (this.gateIO.inputSignals == null) {
                    this.gateIO.inputSignals = [];
                }
            }
        }
    },
    computed: {
        bcItems() {
            return [
                {
                    text: 'models',
                    href: '/models',
                },
                {
                    text: this.name,
                    href: '/models/editModel/' + this.gate,
                    active: true,
                }
            ]
        },
        typeselected() {
            if(this.gateIO.id === 0 && this.gateIO.type !== 0){
                return this.gateIO.type;
            }

        },
    },
    mounted() {
        if (this.modelId > 0) {
            modelService.getById('Get', this.modelId)
                .then(data => {
                    this.fillView(data);
                });
        } else {
            modelService.getGateIOSelects()
                .then(data => {
                    this.gateTypes = data.gateTypes;
                    this.icons = data.icons;
                });
        }
    }
}
</script>